<template>
	<div 
		class="branch friends pa-3 mb-150"
	>
		<div
			class="mt-3"
		>
			<v-text-field
				v-model="item.salesManager"
				placeholder="영업점 이름을 입력하세요"
				label="이름"
				outlined
				color="success2"
				success-color="success2"
				type="email"
			>
			</v-text-field>
			<v-text-field
				v-model="item.phoneNumber"
				placeholder="-없이 숫자만 입력하세요"
				label="연락처"
				outlined
				
				color="success2"
				success-color="success2"
				messages="입력된 번호로 가입URL이 포함된 SMS를 발신합니다"
				type="number"
				
				append-icon="send"
				
				@click:append="send"
			>
			</v-text-field>
		</div>
		
		<div
			class="text-caption mt-5"
		>
			등록된 영업점
		</div>
		
		<v-divider class="mt-5 mb-5"></v-divider>
		
		<v-card
			class="pa-0"
			elevation="0"
		>
			<v-list
				v-for="(item, index) in list_fav"
				:key="'fav_' + index"
				
				class="pa-0 ma-0 "
				:class="index + 1 < list_fav.length ? 'border-bottom' : ''"
			>
				<v-list-item
					class="pa-0 ma-0"
				>
					<v-list-item-avatar
						class="pa-0 ma-0"
						size="60"
					>
						<v-icon 
							large
							:color="item.status_color"
						>mdi-account-circle</v-icon>
					</v-list-item-avatar>
					<v-list-item-content
						class="pa-0 ma-0"
					>
						<div
							class="warp-start"
						>
							<span class="text-subtitle-2 font-weight-bold">{{ item.salesManager }}</span> <br/>
							<span class="text-caption">{{ item.salesPhoneNumber | makeTell }}</span>
							
							<v-btn
								class="star success2 rounded-pill"
								x-small
							>
								{{ item.status }}
							</v-btn>
						</div>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			
		</v-card>
		
		<v-card
			v-if="list_fav.length <= 0"
			class="mt-5"
			>
			<v-card-text
				class="text-center"
			>
				등록된 영업점이 없습니다
			</v-card-text>
		</v-card>
		
		<div
			class="text-caption mt-5"
		>
			가입 대기
		</div>
		
		<v-divider class="mt-5 mb-5"></v-divider>
		
		
		<v-card
			class="pa-0"
			elevation="0"
		>
			<v-list
				v-for="(item, index) in list_normal"
				:key="index"
				
				class="pa-0 ma-0 border-bottom"
				:class="index + 1 < list_normal.length ? 'border-bottom' : ''"
			>
				<v-list-item
					class="pa-0 ma-0"
				>
					<v-list-item-avatar
						class="pa-0 ma-0"
						size="60"
					>
						<v-icon 
							:color="item.status_color"
							large
						>mdi-account-circle</v-icon>
					</v-list-item-avatar>
					<v-list-item-content
						class="pa-0 ma-0"
					>
						<div
							class="warp-start"
						>
							<span class="text-subtitle-2 font-weight-bold">{{ item.salesManager }}</span> <br/>
							<span class="text-caption">{{ item.salesPhoneNumber | makeTell }}</span>
							
							<span
								class="star"
							>
								<v-btn
									class="text-white"
									color="grey"
									x-small
								>
									{{ item.status }}
								</v-btn>
								
				
								<v-btn
									color="red"
									class="rounded-pill text-white"
									x-small
									@click="remove(item.orgSeq)"
									icon
								>
									<v-icon>mdi-delete-forever-outline</v-icon>
								</v-btn>
							</span>
						</div>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>
		
		<v-card
			v-if="list_normal.length <= 0"
			class="mt-5"
			>
			<v-card-text
				class="text-center"
			>
				등록된 영업점 가입요청이 없습니다
			</v-card-text>
		</v-card>
		
		
	</div>
</template>

<script>
	export default{
		name: 'BranchFriends'
		,props: ['user', 'callBack']
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getData()
		}
		,data: function(){
			return {
				program: {
					title: '번호로 직접입력'
					,not_navigation: true
				}
				,contactPage: 1
				,items: []
				,item: {
					salesManager: ''
					,phoneNumber: ''
				}
			}
		}
		,computed: {
			list_fav: function(){
				console.log('computed list_fav')
				return this.items.filter(function(item, index){
					
					if(item.useFlag == 'N'){
						return ''
					}
					
					if(item.status == 'none'){
						item.status_color = ''
					}else if(item.status == 'wait'){
						item.status_color = 'orange'
					}else if(item.status == 'apply'){
						item.status_color = 'success2'
					}
					
					const kor = ["ㄱ","ㄲ","ㄴ","ㄷ","ㄸ","ㄹ","ㅁ","ㅂ","ㅃ","ㅅ","ㅆ","ㅇ","ㅈ","ㅉ","ㅊ","ㅋ","ㅌ","ㅍ","ㅎ"];
					let result = '';
					
					
					let utf = item.salesManager.charCodeAt(0) - 44032;
					
					result = kor[Math.floor(utf/588)];
					
					item.initial = result
					item.status = '가입완료'
					item.index = index
					
					return item
				})
			}
			,list_normal: function(){
				console.log('computed list_normal')
				return this.items.filter(function(item, index){
					
					if(item.useFlag == 'Y'){
						return ''
					}
					
					if(item.status == 'none'){
						item.status_color = ''
					}else if(item.status == 'wait'){
						item.status_color = 'orange'
					}else if(item.status == 'apply'){
						item.status_color = 'success2'
					}
					
					item.status = '대기중'
					item.index = index
					
					return item
				})
			}
		}
		,methods: {
			getData: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/sales/joins'
					,request_params: {
						sortType: 'Name'
					}
					,authorize: true
					,callBack: 'setItems'
				})
			}
			,setItems: function(call){
				console.log('branch guest setitems')
				console.log(call)
				if(call.result){
					this.$set(this, 'items', call.items.content)
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,searchFriends: function(call){
				
				let show = 0
				for(let index in this.items){
					let item = this.items[index]
					if((item.salesManager).indexOf(call) > -1 || (item.phoneNumber).indexOf(call) > -1){
						item.hide = false
						show++
					}else{
						item.hide = true
					}
				}
				console.log('show: ' + show)
			}
			,removeFav: function(item){
				console.log('removeFav')
				item.fav = false
				this.$set(this.items, item.index, item)
				localStorage.removeItem(item.phoneNumber)
			}
			,setFav: function(item){
				console.log(item)
				console.log('setFav')
				item.fav = true
				this.$set(this.items, item.index, item)
				localStorage.setItem(item.phoneNumber, true)
			}
			,send: function(){
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/sales/joins'
					,request_params: [this.item]
					,authorize: true
					,callBack: 'sendResult'
				})
			}
			,sendResult: function(call){
				if(call.result){
					this.$emit('alertMsg', 'success', '영업점 가입요청이 완료되었습니다')
					this.getData()
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,remove: function(orgSeq){
				if(confirm('대기중인 요청건을 삭제하시겠습니까?')){
					this.$emit('axios', {
						request_type: 'delete'
						,request_url: '/sales/joins/' + orgSeq
						,authorize: true
						,callBack: 'removeResult'
					})
				}
			}
			,removeResult: function(call){
				if(call.result){
					this.$emit('alertMsg', 'success', '삭제되었습니다')
					this.getData()
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
		}
		,watch: {
			search: {
				handler: function(call){
					this.searchFriends(call)
				}
			}
			,callBack: {
				deep :true
				,handler: function(call){
					if(call.name == 'setItems'){
						this.setItems(call)
					}else if(call.name == 'sendResult'){
						this.sendResult(call)
					}else if(call.name == 'removeResult'){
						this.removeResult(call)
					}
				}
			}
		}
	}
</script>

<style>
	.branch.friends .warp-star {position: relative;}
	.branch.friends .star {position: absolute; right: 0px; top: 10px;}
	.branch.friends .none {display: none;}
	.v-application--is-ltr .v-messages {text-align: right !important;}
	.v-application .success--text {color: #00bfa5 !important; caret-color: #00bfa5 !important;}
	
	.branch.friends .border-bottom {border-bottom: 1px solid #ddd;}
</style>






















